<!-- eslint-disable no-param-reassign -->
<template>
  <b-card class="py-2 px-2">
    <b-row class="align-items-end mb-2">
      <b-col
        class="mb-1 mb-sm-0"
        cols="12"
        md="4">
        <DatePickerFilter
          v-model="filter.startDate"
          label="Start Date" />
      </b-col>
      <b-col
        class="mb-1 mb-sm-0"
        cols="12"
        md="4">
        <DatePickerFilter
          v-model="filter.endDate"
          label="End Date" />
      </b-col>
      <b-col
        cols="4"
        class="mb-1 mb-sm-0">
        <FilterDropdown
          v-model="filter.userTeamId"
          label="Team"
          placeholder="Team"
          multiple
          :close-on-select="false"
          :options="options.userTeam"
          label-option="name"
          return-value="id"
        />
      </b-col>
      <b-col
        cols="4"
        class="mb-1 mb-sm-0">
        <FilterDropdown
          v-model="filter.userGroupId"
          label="Group"
          placeholder="Group"
          multiple
          :close-on-select="false"
          :options="options.userGroup"
          label-option="name"
          return-value="id"
        />
      </b-col>
      <b-col
        cols="4"
        class="mb-1 mb-sm-0">
        <FilterDropdown
          v-model="filter.userIds"
          label="User"
          multiple
          :close-on-select="false"
          placeholder="User"
          :options="options.user"
          label-option="fullName"
          return-value="id"
        />
      </b-col>
      <b-col
        sm="12"
        md="4"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          class="w-100 mt-2"
          variant="primary"
          @click="submitExport()"
        >
          <span class="align-middle">Export</span>
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import FilterDropdown from '@/components/Filter/FilterDropdown.vue'
import DatePickerFilter from '@/components/Filter/DatePickerFilter.vue'
import ReportProvider from '@/resources/ReportProvider'
import UserTeamProvider from '@/resources/UserTeamProvider'
import UserGroupProvider from '@/resources/UserGroupProvider'
import dayjs from 'dayjs'
import UserProvider from '@/resources/UserProvider'

const ReportService = new ReportProvider()
const UserTeamService = new UserTeamProvider()
const UserGroupService = new UserGroupProvider()
const UserService = new UserProvider()

export default {
  components: {
    DatePickerFilter,
    FilterDropdown
  },
  data () {
    return {
      isDeleteModalActive: false,
      filter: {
        startDate: dayjs().format('YYYY-MM-DD'),
        endDate: dayjs().format('YYYY-MM-DD'),
        userIds: [],
        userTeamId: [],
        userGroupId: []
      },
      pagination: {
        page: 1,
        limit: 10,
        totalDocs: 10
      },
      fields: [
        { key: 'ticketStatus', label: 'สถานะ', sortable: false },
        { key: 'code', label: 'เลขที่รับเรื่อง', sortable: false },
        { key: 'createdAt', label: 'วันที่แจ้งเรื่อง', sortable: false },
        { key: 'closedDate', label: 'วันที่ปิดงาน', sortable: false },
        { key: 'tickeType.name', label: 'ประเภท', sortable: false },
        { key: 'contactChannel.name', label: 'ช่องทางการแจ้งเรื่อง', sortable: false },
        { key: 'subject', label: 'หัวข้อ', sortable: false },
        { key: 'subjects', label: 'เรื่อง', sortable: false },
        { key: 'ticketDetail', label: 'รายละเอียด', sortable: false },
        { key: 'ticketResolve', label: 'การแก้ปัญหา', sortable: false },
        { key: 'customerName', label: 'ผู้แจ้งเรื่อง', sortable: false },
        { key: 'progressBy', label: 'ผู้รับเรื่อง', sortable: false }
      ],
      items: [],
      selectedItem: {},
      initialTicketType: {},
      deleteItem: {},
      ticketType: [],
      startDate: '',
      endDate: '',
      options: {
        userTeam: [],
        userGroup: [],
        user: []
      }
    }
  },
  created () {
    this.getAllUserTeam()
    this.getAllUserGroup()
    this.getAllAgent()
  },
  methods: {
    async getAllUserTeam () {
      try {
        const { data } = await UserTeamService.findAllTeamName()
        if (data) {
          this.options.userTeam = data
        }
      } catch (error) {
        console.error(error)
      }
    },
    async getAllUserGroup () {
      try {
        const { data } = await UserGroupService.findAllGroupCode()
        if (data) {
          this.options.userGroup = data
        }
      } catch (error) {
        console.error(error)
      }
    },
    async getAllAgent () {
      try {
        const { data } = await UserService.paginate(1, 999999999)
        this.options.user = data
        if (data) {
          this.options.user = data.map((item) => ({
            ...item,
            fullName: `${item?.firstName} ${item?.lastName}`
          }))
        }
      } catch (error) {
        console.error(error)
      }
    },
    exportCSV (data) {
      const extractPlainTextFromHTML = (html) => {
        if (!html) {
          return ''
        }
        const base64Regex = /data:[^;]+;base64[^"]*/g

        // Remove base64-encoded strings
        const noBase64HTML = html.replace(base64Regex, '')

        // Parse the remaining HTML content
        const div = document.createElement('div')
        div.innerHTML = noBase64HTML

        // Remove all img elements
        div.querySelectorAll('img').forEach((element) => {
          element.parentNode.removeChild(element)
        })

        // Find and remove base64-encoded images
        div.querySelectorAll('[src^="data:image"]').forEach((element) => {
          element.parentNode.removeChild(element)
        })

        // Remove elements that are not part of CSV format (e.g., links)
        div.querySelectorAll('a').forEach((element) => {
          element.parentNode.removeChild(element)
        })

        // Replace newline characters with a space
        div.querySelectorAll('br').forEach((element) => {
          // eslint-disable-next-line no-param-reassign
          element.outerHTML = ' '
        })

        // Get all the text content within the div
        const textContent = div.textContent || div.innerText || ''

        // Replace line breaks and multiple spaces with a comma
        const csvText = textContent
          .replace(/\n/g, ' ')
          .replace(/\s+/g, ' ')
          .replace(/,/g, '') // Remove commas

        return csvText
      }

      const items = data?.map((item) => ({
        'CreateDate + Time': this.returnNotValue(dayjs(item?.createdDateTime).format('YYYY-MM-DD HH:MM:ss')),
        'Ticket No': item?.ticketNo || '',
        'CreateBy_ID': item?.createdById || '',
        'CreateBy': this.returnNotValue(item?.createdBy),
        'ProgressBy_ID': this.returnNotValue(item?.progressById),
        'ProgressBy_Name': this.returnNotValue(item?.progressByName),
        'ProgressBy_Group ': this.returnNotValue(item?.progressByGroup),
        'ProgressBy_Team': this.returnNotValue(item?.progressByTeam),
        'Ticket Type': item?.ticketType || '',
        'Category ID': item?.categoryId || '',
        'Category Lv1': item?.categoryLv1 || '',
        'Category Lv2': item?.categoryLv2 || '',
        'Category Lv3': item?.categoryLv3 || '',
        'Category Lv4': item?.categoryLv4 || '',
        'Category Lv5': item?.categoryLv5 || '',
        'Category Lv6': item?.categoryLv6 || '',
        'Customer ID': item?.categoryLv6 || '',
        'Customer Name': item?.categoryLv6 || '',
        'Customer Type': item?.categoryLv6 || '',
        'Customer Group': item?.categoryLv6 || '',
        'Contact Name': item?.customerName || '',
        'Contact Phone No': item?.contactPhoneNo || '',
        'Contact Channel': item?.contactChannel || '',
        'Transaction ID (Non-Voice)/Voice': item?.transactionId || '',
        'Contact ID (Non-Voice)': item?.contactId || '',
        'Subject': item?.subject || '',
        'Ticket Cause': extractPlainTextFromHTML(item?.ticketCause) || '',
        'Ticket Detail': extractPlainTextFromHTML(item?.ticketDetail) || '',
        'Ticket Solution': extractPlainTextFromHTML(item?.ticketSolution) || '',
        'Priority': item?.priority || '',
        'Plant': item?.plant || '',
        'Qty': item?.qty || '',
        'Time Duration (HH:MM)': item?.time || '',
        'Due Date + Time': this.returnNotValue(dayjs(item?.dueDate).format('YYYY-MM-DD HH:MM:ss')),
        'Closed Date + Time': this.returnNotValue(dayjs(item?.closedDate).format('YYYY-MM-DD HH:MM:ss')),
        'Overdue Duration': item?.overdueDuration || '',
        'SLA Duration': item?.slaDuration || '',
        'Operation Duration': item?.operationDuration || '',
        'Assign Status': item?.assignStatus,
        'Status': item?.status || '',
        'SLA Status': item?.slaStauts || '',
        'Remark 1': item?.remark1 || '',
        'Remark 2': item?.remark2 || '',
        'Remark 3': item?.remark3 || ''
      }))
      const replacer = (key, value) => (value === null ? '' : value) // specify how you want to handle null values here
      const header = Object.keys(items[0])
      const csv = [
        header.join(','), // header row first
        ...items.map((row) => header.map((fieldName) => JSON.stringify(row[fieldName], replacer)).join(','))
      ].join('\r\n')
      const universalBOM = '\uFEFF'
      const csvContent = `data:text/csv; charset=utf-8,${encodeURIComponent(universalBOM + csv)}`
      // const encodedUri = encodeURIComponent(csvContent)
      const link = document.createElement('a')
      link.setAttribute('href', csvContent)
      link.setAttribute('download', `TICKET_DETAIL_REPORT_${new Date().toLocaleString('en-US')}.csv`)
      document.body.appendChild(link)
      link.click()
    },
    returnNotValue (data) {
      if (!data || data === 'undefined' || data === '  undefined' || data === 'undefined undefined' || data === 'Invalid Date') {
        return ''
      }
      return data
    },
    async submitExport () {
      try {
        await ReportService.ticketDetailReport(this.filter)
        // const { data } = await ReportService.ticketDetailReport(this.filter)
        // this.exportCSV(data)
      } catch (error) {
        console.error(error)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.v-select {
  background: white;
}
.card-body {
  padding: 0;
}
::v-deep input.vs__search::placeholder {
  color: #B9B9C3;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}
</style>
